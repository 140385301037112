import { ActivatedRoute, Router } from '@angular/router';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { AuthUtils } from './auth.utils';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Injectable } from '@angular/core';
import { SwalService } from '../services/swal/swal.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private subscribe = new Subject<void>();
	/**
	 * Constructor
	 */
	constructor(
		private _authService: AuthenticationService,
		private _activatedroute: ActivatedRoute,
		private _router: Router,
		private swalService: SwalService,
	) {}

	/**
	 * Intercept
	 *
	 * @param req
	 * @param next
	 */
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>> {
		let newReq = req.clone();

		// Verificar si la solicitud se dirige a la API de Google Maps
		if (req.url.startsWith('https://maps.googleapis.com')) {
			// Si es así, clonar la solicitud y eliminar el encabezado 'Authorization'
			const modifiedRequest = req.clone({
				headers: req.headers.delete('Authorization'),
			});
			// Continuar con la solicitud modificada
			return next.handle(modifiedRequest);
		}

		if (
			this._authService.accessToken &&
			!AuthUtils.isTokenExpired(this._authService.accessToken)
		) {
			const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

			newReq = req.clone({
				headers: req.headers
					.set('Authorization', 'Bearer ' + this._authService.accessToken)
					.set('Time-Zone', timeZone),
			});
		}

		return next.handle(newReq).pipe(
			catchError((err) => {
				const defaultError = 'Se produjo un error al procesar su solicitud.';

				if (err instanceof HttpErrorResponse && err.status === 401) {
					const redirectURL = this._authService.redirectURL;

					localStorage.removeItem('access_token');
					this._router.navigateByUrl('/auth/login');
					throw err;
				}
				if (err.status == 409) {
					throw err.error.errorMsg;
				}

				if (err.status == 500) {
					// this.swalService.showError(err.error.errorMsg, 'center', true);
					throw err.error.errorMsg;
				}

				if (err.status == 403) {
					throw 'No tienes permiso para realizar esta acción.';
				}

				if (err.status == 400) {
					if (req.method === 'POST' && err.error.data) {
						// Recorre todas las propiedades del objeto data y muestra cada mensaje
						let messages = '';
						let i = 1;
						for (const key in err.error.data) {
							let validators = err.error.data[key];

							if (typeof validators === 'object') {
								validators.forEach((value: string) => {
									messages += `<span class="py-1">${i}.- ${value}</span><br>`;
								});
								i++;
							}
						}

						this.swalService.showValidators(messages, 'center', true);
					}
				}

				throw defaultError;
			}),
		);
	}
}
